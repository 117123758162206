import React from 'react';

import Layout from 'components/layout/Layout';

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <h1>Not Found</h1>
      <p>This page does not exist.</p>
    </Layout>
  );
};

export default NotFoundPage;
